import React from 'react';

import matchSorter from 'match-sorter';

import _ from "underscore";

import { Button, Grid, Input, Dropdown, Form, Icon } from 'semantic-ui-react';

import IMComponent from '../Components/IMComponent';
import { convertDate } from '../../components/utility/Helper';

export default class LicenseGrid extends IMComponent {

    constructor(props) {
        super(props);
        this.manageUsersRef = [];
        this.updateLicenseRef = [];
        this.state = {
            ...this.state,
            filter: { email: "" },
            queryFirstName: '',
            queryLastName: '',
            orderBy: [],
            queryProduct: '',
            queryStatus: "Active",
            queryType: ''
        };
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.changeFilterValue = this.changeFilterValue.bind(this);
        this.callParentFilterChange = this.callParentFilterChange.bind(this);
        this.changeEmailOrderBy = this.changeEmailOrderBy.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.changeInputText = this.changeInputText.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.getEditLicenseOptions = this.getEditLicenseOptions.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }

    onFilterChanged = () => {
        const { queryProduct, queryStatus, queryEmail, queryType } = this.state;
        let resultData = this.props.data;
        if (queryEmail)
            resultData = matchSorter(resultData, queryEmail, { keys: [x => x.name, x => x.manager] });
        if (queryProduct)
            resultData = resultData.filter(x => x.productId == queryProduct);
        if (queryStatus) {
            if (queryStatus == "all") {
                resultData = resultData;
            } else {
                resultData = resultData.filter(x => x.status == queryStatus);
            }

        }

        if (queryType)
            resultData = resultData.filter(x => x.trial.toString() == queryType);
        return _.sortBy(resultData, row => row.accountName);
    }

    changeFilterValue = function (e, d) {
        switch (d.name) {
            //       case "queryEmail": this.setState({ queryEmail: d.value }, () => { this.callParentFilterChange(); }); break;
            case "queryProduct": this.setState({ queryProduct: d.value }, this.callParentFilterChange); break;
            case "queryStatus": this.setState({ queryStatus: d.value }, this.callParentFilterChange); break;
            case "queryType": this.setState({ queryType: d.value }, this.callParentFilterChange); break;
            default: break;
        }

    }

    changeInputText = (e) => {
        let value = e.target.value
        this.setState({ filter: { email: value } }, this.callParentFilterChange);
        this.callParentFilterChange();
    }

    callParentFilterChange = function () {
        const { queryProduct, queryStatus, queryEmail, queryType, orderBy, filter } = this.state;
        this.props.onLicenseFilterChanged(queryProduct, queryStatus, queryEmail, queryType, orderBy, filter);
    }

    truncateEmail = (email) => {
        if (email.length > 21) {
            return email.substring(0, 18) + "..";
        }
        return email;
    }

    getProductName = (productId) => {
        switch (productId) {
            case 1: return 'FS Pro Standard';
            case 2: return 'FS Pro for Outlook';
            case 3: return 'FS Pro Business';
            case 7: return 'FS Pro Assistant';
            default: return '';
        }
    }

    getEditLicenseOptions(item, index) {
        const items = [
            ... (item.canManage || this.props.isAdmin) ? [{ text: "Manage Users" }] : [],
            ... this.props.isAdmin ? [{ text: "Update License" }] : []
        ];

        let options = [
        ];

        for (let item of items) {
            options = [...options,
            <Dropdown.Item
                style={{ color: '#FF8019' }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleAction}
                id={index}>
                {item.text}
            </Dropdown.Item>
            ];
        }
        return options;
    }

    handleMouseEnter(e) {
        e.target.style.background = '#FF8019';
        e.target.style.color = 'white';
    }

    handleMouseLeave(e) {
        e.target.style.background = 'white';
        e.target.style.color = '#FF8019';
    }

    handleAction(e) {
        let text = e.target.innerText;
        let index = e.target.id;

        switch (text) {
            case "Manage Users":
                this.manageUsersRef[+index].ref.click();
                break;

            case "Update License":
                this.updateLicenseRef[+index].ref.click();
                break;

            default:
                break;
        }
    }

    renderData = (data) => {
        const { showRole } = this.props;
        if (showRole)
            return data.map((item, index) =>
                <Grid.Row key={index}>
                    <Grid.Column width={5} key={index + "a"}>

                        <div className="center">
                            {(item.productId == 1) ? <img className="license__icon" src="img/FSPro2020.jpg" alt="product icon" /> : ''}
                            {(item.productId == 2) ? <img className="license__icon" src="img/fsproOutlook.jpg" alt="product icon" /> : ''}
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3} key={index + "b"}>
                        <p>{item.roleName}</p>
                    </Grid.Column>
                    <Grid.Column width={3} key={index + "c"}>
                        <p>{item.firstName}</p>
                    </Grid.Column>
                    <Grid.Column width={3} key={index + "d"}>
                        <p>{item.lastName}</p>
                    </Grid.Column>
                    <Grid.Column width={2} key={index + "e"}>
                        <Button color="red" size="medium" fluid className="right" index={index} id={item.cryptId} onClick={this.props.onRevokeClick}>REVOKE</Button>
                    </Grid.Column>
                </Grid.Row>);
        return data.map((item, index) => {
            return <Grid.Row key={`${index}${Math.random()}`}>
                <Grid.Column width={2} key={`${index}a${Math.random()}`}>
                    <div className="left">
                        <p style={{ wordWrap: "break-word" }}>{this.getProductName(item.productId)}</p>
                        <p title="Organization Name">{item.organizationName}</p>
                    </div>
                </Grid.Column>
                <Grid.Column width={3} key={`${index}b${Math.random()}`}>
                    <p title={item.manager} style={{ wordWrap: "break-word" }}>{/*this.truncateEmail(item.manager)*/item.manager}</p><p>{item.name}</p>
                </Grid.Column>
                <Grid.Column width={2} key={`${index}c${Math.random()}`}>
                    <p style={{ textAlign: "left" }}>{String(item.trial) === 'true' ? 'Trial' : 'Full'}</p>
                </Grid.Column>
                <Grid.Column width={2} key={`${index}b${Math.random()}`}>
                    <p style={{ textAlign: "left" }}>{item.status}</p>
                </Grid.Column>
                <Grid.Column width={2} key={`${index}e${Math.random()}`}>
                    <p style={{ textAlign: "center" }}>{item.startDate}</p>
                </Grid.Column>
                <Grid.Column width={2} key={`${index}f${Math.random()}`}>
                    <p style={{ textAlign: "center" }}>{item.endDate}</p>
                </Grid.Column>
                <Grid.Column width={1} key={`${index}g${Math.random()}`}>

                    <p style={{ fontSize: item.usedCount > 99 || item.userCount > 99 ? "0.85em" : "1em" }}>{item.canManage || this.props.isAdmin ? item.usedCount + ' / ' + item.userCount : ''}</p>
                </Grid.Column>
                <Grid.Column width={2} key={`${index}h${Math.random()}`}>
                    {(item.canManage || this.props.isAdmin) ? <Button ref={x => this.manageUsersRef[index] = x} style={{ display: 'none' }} id={item.encryptedId} className="ui primary button fluid" onClick={this.props.handleLicenseUsageFilter}>MANAGE USERS</Button> : null}
                    {(this.props.isAdmin) ? <div><Button ref={x => this.updateLicenseRef[index] = x} style={{ display: 'none' }} id={item.encryptedId} className="ui button primary small" onClick={this.props.handleLicenseEdit}>UPDATE</Button></div> : null}

                    {(this.props.isAdmin) ?
                        <div className='im_dropdown_button_field'>
                            <Dropdown
                                button
                                text='Edit'>
                                <Dropdown.Menu>
                                    {this.getEditLicenseOptions(item, index)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        : (item.canManage || this.props.isAdmin) && <Button id={item.encryptedId} className="ui primary button fluid" onClick={this.props.handleLicenseUsageFilter}>Manage Users</Button>}
                </Grid.Column>
            </Grid.Row>
        });
    }


    changeEmailOrderBy = function () {
        const orderBy = this.state.orderBy.filter(function (ordering) { return ordering.name !== "email"; });
        const order = this.state.orderBy.find(function (ordering) { return ordering.name === "email"; });
        this.setState({
            orderBy: [
                ...orderBy,
                {
                    name: "email",
                    dir: order && order.dir === "asc" ? "desc" : "asc"
                }
            ]
        }, this.callParentFilterChange);
    }


    renderHeader = function () {
        const { queryEmail, queryType, queryStatus, queryProduct } = this.state;

        const productOptions = [
            { key: "", value: "", text: "Show All", color: "#4c5a61" },
            { key: "1", value: "1", text: "FS Pro Standard", color: "#4c5a61" },
            { key: "3", value: "3", text: "FS Pro Business", color: "#4c5a61" },
            { key: "2", value: "2", text: "FS Pro for Outlook", color: "#4c5a61" },
            { key: "7", value: "7", text: "FS Pro AI Assistant", color: "#4c5a61" }
        ];

        const typeOptions = [
            { key: "all", value: "", text: "Show All", color: "#4c5a61" },
            { key: "true", value: "true", text: "Trial", color: "#4c5a61" },
            { key: "false", value: "false", text: "Full", color: "#4c5a61" }
        ];

        const statusOptions = [
            { key: "all", value: "all", text: "Show All", color: "#4c5a61" },
            { key: "true", value: "Active", text: "Active", color: "#4c5a61" },
            { key: "false", value: "Expired", text: "Expired", color: "#4c5a61" }
        ];

        const styleButtonHeader = {
            cursor: "pointer"
        }
        const order = this.state.orderBy.find(function (ordering) { return ordering.name === "email"; });
        const emailOrderIcon = !order ? "arrows alternate vertical" : `arrow ${(order.dir === "asc" ? "down" : "up")}`;
        return [
            <Grid.Row key="header">
                <Grid.Column width={2} className="panel__text--title">
                    PRODUCT
                </Grid.Column>
                <Grid.Column width={3} className="panel__text--title" style={{ textAlign: "left" }}>

                    {this.props.isAdmin ? <div onClick={this.changeEmailOrderBy} style={styleButtonHeader}>
                        EMAIL<Icon name={`${emailOrderIcon}`} />
                    </div> :
                        "EMAIL"}
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "left" }}>
                    TYPE
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "left" }}>
                    STATUS
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center", fontSize: "1.08em" }}>
                    VALID FROM
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center", fontSize: "1.08em" }}>
                    VALID UNTIL
                </Grid.Column>
                <Grid.Column width={1} className="panel__text--title">
                    #USERS
                </Grid.Column>
                <Grid.Column width={1} className="panel__text--title">
                </Grid.Column>
            </Grid.Row>,
            <Grid.Row key="headerSub" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <Grid.Column width={2} className="panel__text--title">

                </Grid.Column>
                <Grid.Column width={3} className="panel__text--title">

                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title">

                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title">

                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "12px" }}>(mm/dd/yyyy)</span>
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title" style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "12px" }}>(mm/dd/yyyy)</span>
                </Grid.Column>
                <Grid.Column width={1} className="panel__text--title">

                </Grid.Column>
                <Grid.Column width={1} className="panel__text--title">
                </Grid.Column>
            </Grid.Row>,
            <Grid.Row key="filter">

                <Grid.Column width={2} >
                    <Dropdown
                        fluid
                        search
                        className="main-page"
                        placeholder="Show All"
                        selection
                        name="queryProduct"
                        scrolling
                        options={productOptions}
                        value={queryProduct}
                        onChange={this.changeFilterValue}
                    />
                </Grid.Column>
                <Grid.Column width={3} className="panel__text--title">
                    <Input
                        style={{ height: "40px" }}
                        name="queryEmail"
                        type="text"
                        className="form-input"
                        fluid
                        value={this.state.filter.email}
                        onChange={this.changeInputText} />
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title">

                    <Dropdown
                        fluid
                        search
                        className="ui im_text_field main-page"
                        placeholder="Show All"
                        selection
                        name="queryType"
                        scrolling
                        options={typeOptions}
                        value={queryType}
                        onChange={this.changeFilterValue}
                    />                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title">
                    <Dropdown
                        fluid
                        search
                        className="ui im_text_field main-page"
                        placeholder="Show All"
                        selection
                        name="queryStatus"
                        value={queryStatus}
                        scrolling
                        options={statusOptions}
                        onChange={this.changeFilterValue}
                    />
                </Grid.Column>
            </Grid.Row>];
    }

    render() {
        //const data = this.onFilterChanged();
        return [this.renderHeader(), this.renderData(/*data*/this.props.data)];
    }
}
